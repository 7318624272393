$(function () {
    appendArrow();

    var winWidth = $(window).width();
    $('#nav li').on('mouseenter', function () {
        if (winWidth > 990) {
            $(this).children('.sub').next().show();
        }
    }).on('mouseleave', function () {
        if (winWidth > 990) {
            $(this).children('.sub').next().hide();
        }
    });

    $('.subnavArrow').on('click', function () {
        $(this).parent().siblings().find('ul').slideUp();
        $(this).next().next().slideToggle();
    });

    $(window).on('resize', function () {
        winWidth = $(window).width();
        if (winWidth > 990) {
            $('.navIcon').removeClass('navActive');
        } else if (winWidth < 990) {
            $('.navBtn').removeClass('navActive');
            $('#nav').removeClass('active');
            $('#nav ul li ul').slideUp();
        }
    });

    $('.subnavArrow').on('click', function () {
        var navActive = $(this).hasClass('active');
        if (!navActive) {
            $(this).addClass('active');
        }
        if (navActive) {
            $(this).removeClass('active');
        }
    });

    $('.navBtn').on('click', function () {
        var navActive = $('.navBtn').hasClass('navActive');
        if (!navActive) {
            $('.navBtn').addClass('navActive');
        }
        if (navActive) {
            $('.navBtn').removeClass('navActive');
        }
        if (!$('#nav').hasClass('active')) {
            $('#nav').addClass('active');
        } else {
            $('#nav').removeClass('active');
        }
    });

    $('.colorTitle').on('click', function () {
        if (winWidth < 667) {
            filterActive($(this));
            $('.priceBox').slideUp();
            $('.itemsBox').slideUp();
            $('.colorBox').slideToggle();
        }
    });
    $('.priceTitle').on('click', function () {
        if (winWidth < 667) {
            filterActive($(this));
            $('.colorBox').slideUp();
            $('.itemsBox').slideUp();
            $('.priceBox').slideToggle();
        }
    });
    $('.itemsTitle').on('click', function () {
        if (winWidth < 667) {
            filterActive($(this));
            $('.colorBox').slideUp();
            $('.priceBox').slideUp();
            $('.itemsBox').slideToggle();
        }
    });

    // Initiate character counter
    charsCounter();
});

// page reload code that should not be run on checkout and thank-you page
if (!WebPageObj.checkout && !WebPageObj.thankYou) {
    $(function () {
        setInterval(
            function () {
                $.ajax({
                    url: '/ajax/checkremarketingreload',
                    type: 'GET'
                }).done(function (response) {
                    if (response == "OK") {
                        location.href = document.location.href;
                    }
                });

            }, 60000);
    });
}

// Js is to laod the shopper approved reviews
if (WebPageObj.customerReview) {
    var sa_interval = 5000;
    var js = window.document.createElement('script'); 
        js.src = 'https://www.shopperapproved.com/widgets/33789/merchant/review-page/02exC7bfMh5c.js'; 
        js.type = 'text/javascript';
        document.getElementsByTagName("head")[0].appendChild(js);
}
